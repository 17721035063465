import React from 'react'

import {Container, Title} from '../../styles/contact.js'


export default function Team () {
  return (
    
      <Container>
          <Title>CONTACT US</Title>
      </Container>

  )
}
