import React from 'react'

import {Container, Column, ColumnDark, Address, Mail, Phone} from '../../styles/footer.js'
import '../../styles/footer.css'
import mapIcon from '../../images/iconMap.png'
import telIcon from '../../images/iconTel.png'
import emailIcon from '../../images/iconMail.png'


export default function Footer () {
  return (
    <Container id="Contact us">
      <Column>
        <a href="https://www.google.com/maps/place/Ruta+N/@6.2646247,-75.5666072,15z/data=!4m2!3m1!1s0x0:0x99f177323f8c67d5?sa=X&ved=2ahUKEwjd3O-WiZnhAhVIq1kKHbGXCfAQ_BIwDnoECA0QCA"
          target="_blank" rel="noopener noreferrer" className='address'>
          {/* <i className="fas fa-map-marker-alt" /> */}
          <img src={mapIcon} class="icon-map"></img>
        </a>
        <Address>
          Complejo Ruta N Calle 67 Nº 52-20 Piso 2 Torre A. Medellín - Colombia
        </Address>
      </Column>
      <Column>
        <span className="mail">
          {/* <i className="fas fa-envelope" /> */}
          <img src={emailIcon} class="icon-email"></img>
        </span>
        <Mail>hello@bcfort.com</Mail>
      </Column>
      <Column>
        <span className="phone">
          {/* <i className="fas fa-phone-square" /> */}
          <img src={telIcon} class="icon-tel"></img>
        </span>
        <Phone>+57 316 8288091</Phone>
      </Column>
      <ColumnDark>
        <p className='text-footer'>BCFort</p>
        <p className='text-footer'>Blockchain for Bussiness</p>
        <br/>
        {/* <p className='text-footer author'>
          developed by
          <a href="https://github.com/Byhako" rel="noopener noreferrer" 
            target="_blank"
          >Byhako</a>
        </p> */}
      </ColumnDark>
    </Container>
  )
}
