import styled from 'styled-components'

// font-family: 'Montserrat', sans-serif;

export const Nav = styled.nav `
  width: 100vw;
  height: 76px;
  padding: 0
  display: flex;
  position: fixed;
  top: 0;
  background-color: white;
  align-items: center;
  z-index: 8;
  box-shadow: 0 4px 5px -2px rgba(0,0,0,.5);
`

export const Logo = styled.img `
  max-width: 100%;
  height: 62px;
  padding: 7px 15px;
  @media screen and (max-width: 750px) {
    padding: 0px 15px;
  };
  cursor: pointer;
`

export const ContainerLinks = styled.ul `
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 90px;
  height: 80px;
`

export const Item = styled.li `
  color: #404040;
  font-size: 18px;
  font-family: 'Ubuntu','Lato', sans-serif;
`