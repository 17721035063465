import React from 'react'

import {LandingImage, Text} from '../../styles/landing.js'


export default function Landing () {
  return (
    <LandingImage id="Landing">
      {/* <video autoPlay loop muted>
        <source src={theVideo} type="video/mp4"/>
        <source src={theVideo} type="video/ogg"/>
      </video> */}
      <Text>
        HARNESS THE POWER OF <br/> BLOCKCHAIN
      </Text>
    </LandingImage>
 
  )
}
