import React, { Component, Fragment }  from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import Home from './Home'
// import Blog from './Blog'

class AppRouter extends Component {

  render () {
    return (
      <BrowserRouter>
        <Fragment>
          <Switch>
            <Route exact path='/' component={Home} />
            {/*<Route exact path='/blog' component={Blog}  />*/}
          </Switch>
        </Fragment>
      </BrowserRouter>
    )
  }
}

export default AppRouter
