import React from 'react'

import blockchain from '../../images/CB.png'
import artificial from '../../images/AI.png'
import consulting from '../../images/CS.png'
import education from '../../images/ES.png'

import {Container, Title, Row, Card, ContentImage1,
  ContentImage2, ContentImage3, ContentImage4, Image1, Image2,
  Image3, Image4, Subtitle, Line1, Line2, Line3, Line4, Text } from '../../styles/services.js'


export default function Services () {
  return (
    <Container id="our-services">
      <Title>
        OUR SERVICES
      </Title>

      <Row>
        <Card>
          <ContentImage1>
            <Image1 src={blockchain} alt="blockchain"/>
          </ContentImage1>
          <Subtitle>CUSTOM BLOCKCHAIN</Subtitle>
          <Line1 />
          <Text>
          Adopt Blockchain technology in your organization and enabling to unleash all
          the advantages this technology can provide you. 
          Acquire tailored Blockchains solutions for your specific business needs.
          </Text>
        </Card>

        <Card>
          <ContentImage2>
            <Image2 src={artificial} alt="artificial"/>
          </ContentImage2>
          <Subtitle>ARTIFICIAL INTELLIGENCE</Subtitle>
          <Line2 />
          <Text>
          Enhance your business productivity, We provide solutions using AI technology
          to improve your processes, automatized repetitive tasks and discover new
          information in your data.
          </Text>
        </Card>

        <Card>
          <ContentImage3>
            <Image3 src={consulting} alt="consulting"/>
          </ContentImage3>
          <Subtitle>CONSULTING SERVICES</Subtitle>
          <Line3 />
          <Text>
          Find the best strategy to integrate blockchain benefits with your business. 
          We provide a complete assessment on how is the most suitable architecture to 
          fit this technology with the current company infrastructure or products.
          </Text>
        </Card>

        <Card>
          <ContentImage4>
            <Image4 src={education} alt="education"/>
          </ContentImage4>
          <Subtitle>EDUCATION SERVICES</Subtitle>
          <Line4 />
          <Text>
          Take your team to the next level! We have  expertise in technical educational 
          programs, design and carry out bespoke educational programs in Blockchain technology, 
          analytics and mathematical modelling to address your specific business and technical needs.
          </Text>
        </Card>
      </Row>
    </Container>
  )
}
