export default Reducer

function Reducer (state, action) {
  const reducer = ({
    SET_CANDIDATES,
  })[action.type]

  return (reducer && reducer(state, action)) || state
}

function SET_CANDIDATES (state, action) {
  return { ...state, candidates: action.candidates }
}

