import styled from 'styled-components'

export const LandingImage = styled.div `
  @media screen and (max-width: 450px) {
    height: 320px;
  };
  background: rgba(0, 0, 0, 0.5);
  height: 650px;
  width: 100vw;
  margin-top: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Text = styled.p `
  @media screen and (max-width: 750px) {
    font-size: 40px;
  }
  @media screen and (max-width: 450px) {
    font-size: 30px;
  };
  color: white;
  font-family: 'Ubuntu','Montserrat', sans-serif;
  font-size: 55px;
  font-weight: bold;
  text-align: center;
  padding: 0 18px;
`
