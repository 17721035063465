import React from 'react'

import weenjoy from '../../images/LogoWeenjoyGray.png'
import fast from '../../images/Fastech.png'
import qipux from '../../images/Quipux.png'
import summa from '../../images/summa-logo.png'
// import orometro from '../../images/Logo-Orometro2.png'
import usb from '../../images/usb-medellin.png'

import {Container, Title, Row, Column, Item, ContainerImages,
  Client, Logo, ColumnItems} from '../../styles/about.js'
import '../../styles/about.css'


export default function About () {
  return (
    <Container>
      <Title>ABOUT US</Title>

      <Row>
        <Column>
        We are a startup that provides Blockchain solutions for companies and organizations
        that seek to transform their operations and remain competitive in the 4th Industrial 
        Revolution. We are committed to building next-generation solutions to bring all the 
        benefits of emerging technologies to people's lives, contributing to the diffusion of 
        technology and knowledge throughout the world. We are a group of people mainly from 
        academic institutions aimed at transforming the relationship between academia and 
        business for the benefit of society.
        </Column>
      </Row>
      <Row>
        <ColumnItems>
          <Item>SECURITY</Item>
          <Item>TRUST</Item>
          <Item>PASSION</Item>
        </ColumnItems>
      </Row>

      <div className="our-clients">
        <h5>Our Clients</h5>
      </div>

      <ContainerImages>

        <Client>
          <Logo
           src={weenjoy} alt="weenjoy"
           style={{height: '100px', transform: "scale(0.7)"}} 
            // src={weenjoy} alt="weenjoy"
            // style={{height: '150px',clipPath: 'inset(30% 0 30% 0)',transform: "scale(1.8)"}}
          />
        </Client>
        <Client>
          <Logo
            src={fast} alt="fast"
            style={{height: '500px', clipPath: 'inset(30% 0 30% 0)',transform: "scale(3.0)"} } //transform: "scale(2.0)" clipPath: 'inset(20% 0 20% 0)'
          />
        </Client>
        <Client>
          <Logo
            src={qipux} alt="qipux"
            style={{height: 'auto',clipPath: 'inset(30% 0 30% 0)',transform: "scale(3.0)",objectPosition: "8px 0px"}} // ,clipPath: 'inset(35% 18% 45% 10%)'
          />
        </Client>
        <Client>
          <Logo
            src={summa} alt="summa"
            style={{height: 'auto',clipPath: 'inset(15% 15% 15% 15%)',transform: "scale(2.0)"}}
          />
        </Client>
        {/* <Client>
          <Logo
            src={orometro} alt="orometro"
            style={{height: '110px'}}
          />
        </Client> */}
        

      </ContainerImages>


      <div className="our-clients">
        <h5>Our Academic Allies</h5>
      </div>

      <ContainerImages>
      <Client>
          <Logo 
            src={usb} alt="usb"
            style={{height: '120px'}}
          />
        </Client>
      </ContainerImages>

    </Container>
  )
}
