import styled from 'styled-components'

const colorBlockchain = '#4EAAD2'
const colorArtificial = '#4EAAD2'
const colorConsulting = '#4EAAD2'
const colorEducation = '#4EAAD2'

export const Container = styled.section `
  @media screen and (max-width: 450px) {
    margin: 0 20px;
  };
  padding: 100px 15px;
  margin: 0 88px;
  font-family: "Ubuntu","Montserrat", Helvetica, sans-serif;

`

export const Title = styled.h2 `
  @media screen and (max-width: 450px) {
    padding-bottom: 40px;
    font-size: 32px;
  };
  display: inline-block;
  position: relative;
  margin-top: 15px;
  margin-bottom: 0;
  padding-bottom: 85px;
  font-size: 45px;
  line-height: 40px;
  text-transform: uppercase;
  color: #404040;
  width: 100%;
  text-align: center;
  font-weight: 700;
`

export const Row = styled.div `
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

export const Card = styled.div `
  @media screen and (max-width: 1000px) {
    width: 50%;
  };
  @media screen and (max-width: 750px) {
    width: 100%;
  };
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justity-content: center;
  margin-bottom: 40px;
  padding: 0 15px;
  width: 25%;
  box-sizing: border-box;
  align-items: center;
  position: relative;
  // min-height: 530px;
`

export const ContentImage  = styled.a `
  display: block;
  position: relative;
  width: 145px;
  height: 145px;
  margin: 20px auto;
  border: 10px solid #ececec;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
`

export const ContentImage1 = styled(ContentImage) `
  &:hover { border-color:  ${colorBlockchain}; background-color:${colorBlockchain}; cursor: default;};
`
export const ContentImage2 = styled(ContentImage) `
  &:hover { border-color:  ${colorArtificial}; background-color:${colorBlockchain}; cursor: default;};
`
export const ContentImage3 = styled(ContentImage) `
  &:hover { border-color:  ${colorConsulting}; background-color:${colorBlockchain}; cursor: default;};
`
export const ContentImage4 = styled(ContentImage) `
  &:hover { border-color:  ${colorEducation}; background-color:${colorBlockchain}; cursor: default;};
`

export const ContentImageTeam = styled(ContentImage) `
  width: 174px;
  height: 174px;
  border: 0;
`

export const Image = styled.img `
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  border-radius: 50%
`

export const Image1 = styled(Image) `
  width: 100%;
  height: 100%;
`
export const Image2 = styled(Image) `
  width: 100%;
  height: 100%;
`
export const Image3 = styled(Image) `
  width: 100%;
  height: 100%;
`
export const Image4 = styled(Image) `
  width: 100%;
  height: 100%;
`

export const Subtitle = styled.h3 `
  display: inline-block;
  float: none;
  position: relative;
  width: auto;
  margin-bottom: 30px;
  color: #404040;
  background: none;
  font-size: 17px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
  text-align: center;
`

export const Line = styled.span `
  border: 0;
  height: 3px;
  width: 140px;
  margin-bottom: 10px;
`
export const Line1 = styled(Line) `background-color: ${colorBlockchain}`
export const Line2 = styled(Line) `background-color: ${colorArtificial}`
export const Line3 = styled(Line) `background-color: ${colorConsulting}`
export const Line4 = styled(Line) `background-color: ${colorEducation}`


export const Text = styled.p `
  color: #404040;
  font-size: 16px;
  text-align: center;
  font-weight:300;
  width: 75%;
`

export const Position = styled(Text) `
  margin-top: 0;
` 

export const Linkedin = styled.a `
  &:hover {
    color: #0077b5;
  };
  color: #404040;
  font-size: 20px;
  display: flex;
  justity-content: center;
  // position: absolute;
  // bottom: 0;
`
