import React from 'react'
import Contact from '../Home/Contact'
import {Container, Text} from '../../styles/ribbon.js'


export default function Ribbon () {
  return (
    <Container>
      <Text>Not sure how Blockchain can transform your business? We can help.</Text>
      <Contact />
    </Container>
  )
}
