import styled from 'styled-components'

const colorButton = '#4EAAD2'
const hoverButton = '#1B78B3'


export const LandingImage = styled.div `
  background: rgba(27, 73, 144, 0.6);
  // background: rgb(0.3,0.3,0.3,0.6); 
  height: 60vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
  box-sizing: border-box;
`

export const Section = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center; 
`

export const Text = styled.p `
  color: white;
  font-family: 'Ubuntu','Montserrat', sans-serif;
  font-size: 28px;
  font-weight: 500;
  text-align: center;
  margin: 20px 0;
  padding: 10px 40px;
`

export const Button = styled.a `
  &:hover {
    background-color: ${hoverButton};
  };
  margin: 10px 10px 30px 10px;
  padding: 13px 35px 13px 35px;
  border: none;
  border-radius: 4px;
  text-transform: uppercase;
  background-color: ${colorButton};
  color: white;
  width: 22px;
  height: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Ubuntu','Montserrat', sans-serif;
  text-decoration: none;
  font-size: 18px;

`