import React from 'react'

import {LandingImage, Section, Text, Button} from '../../styles/bot.js'


export default function Bot () {
  return (
    <LandingImage id= "Bot">
      <Section style={{flexDirection: 'column'}}>
          <Text>Try out our bot, and get a tiny look of AI technology power.</Text>
          <Button href="https://t.me/bcfortAI_bot">Start</Button>
      </Section>
    </LandingImage>
  )
}
