import {createGlobalStyle} from "styled-components"
import Gothic from "./Fonts/GOTHIC.TTF"

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
 

  @font-face {
    font-family: 'Gothic';
    src: url('${Gothic}');
        url('${Gothic}') format('truetype'),
    font-weight: normal;
    font-style: normal;
  }

`

export default GlobalStyle;