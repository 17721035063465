import React from 'react'
// import { Link } from 'react-router-dom'
import logoS from '../../images/logoS.jpeg'
import { Link, animateScroll as scroll } from "react-scroll";

import '../../styles/nav.module.css'

import {Nav, Logo, ContainerLinks, Item} from '../../styles/nav.js'


export default function Navbar () {
  return (
    <Nav>
      <Link to='Landing' smooth={true}>
        {/*eslint-disable-next-line*/}
        <Logo src={logoS} alt="logo"/>
      </Link>

      <ContainerLinks>
        <Item>
          <Link to='Landing' smooth={true}>Home</Link>
        </Item>

        <Item>
          <Link to='our-services' smooth={true}>Services</Link>
        </Item>

        <Item>
          <Link to='Bot' smooth={true}>AI bot</Link>
        </Item>

        <Item>
          <Link to='Team' smooth={true}>Team</Link>
        </Item>

        <Item>
          <Link to='Contact us' smooth={true}>Contact us</Link>
        </Item>

      </ContainerLinks>
    </Nav>
  )
}
