import React from 'react'
import Navbar from '../Home/Navbar'
import Landing from '../Home/Landing'
import Services from '../Home/Services'
import Bot from '../Home/Bot'
import About from '../Home/About'
import Team from '../Home/Team'
import Ribbon from '../Home/Ribbon'
import Contact from '../Home/Contact'
import Footer from '../Home/Footer'


import '../../styles/style.module.css'
import vigilancia from '../../images/vigilancia.mp4'



export default function Home () {
  return (
    <div>
      <header>
        <Navbar />
        <Landing />
      </header>
      
      <Services />
      
      <aside>
        <video autoPlay loop muted
        style={{
          position: "absolute",
          width: "100%",
          minHeight: "100vh",
          objectFit: "cover",
          overflow: "hidden",
          zIndex: -1
        }}
        >
          <source src={vigilancia} type="video/mp4"/>
          <source src={vigilancia} type="video/ogg"/>
        </video>
        <Bot />
      </aside>

      <About />
      {/*<Team />*/}
      <Ribbon />

      {/* <aside>
        <Contact />
      </aside> */}
      <Footer />
    </div>
  )
}
