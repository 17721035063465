import styled from 'styled-components'

export const Container = styled.section `
  @media screen and (max-width: 450px) {
    padding: 100px 30px 0;
  };
  color: #fff;
  background: #272727;
  height: max-content;
  font-family: 'Ubuntu','Montserrat', sans-serif;
  padding: 100px 67px 0;
  display: flex;
  flex-direction: column;
`

export const Title = styled.h2 `
  @media screen and (max-width: 450px) {
    padding-bottom: 40px;
    font-size: 32px;
  };
  margin-top: 15px;
  margin-bottom: 0;
  padding: 10px 0 75px;
  font-size: 45px;
  line-height: 40px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  
`

export const Row = styled.div `
  display: flex;
  flex-wrap: wrap;
`

export const Column = styled.div `
  @media screen and (max-width: 750px) and (min-width: 350px) {
    width: 100%;
    padding: 0 15px;
  };
  @media screen and (max-width: 350px) {
    width: 100%;
    text-align: left;
    padding: 0 15px;
  };
  @media screen and (min-width: 1400px) {
    padding: 0 300px;
  };
  display: flex;
  flex-grow: 1;
  color: #939393;
  font-size: 18px;
  width: 50%
  line-height: 25px;
  padding: 0 150px;
  margin-bottom: 78px;
  box-sizing: border-box;
  text-align: justify;
`

export const ColumnItems = styled.div `
  @media screen and (max-width: 750px) and (min-width: 350px) {
    width: 100%;
    padding: 0 15px;
  };

  @media screen and (max-width: 400px) {
    width: 100%;
    flex-direction: column;
    text-align: center;
    padding: 0 15px;
  };
  display: flex;
  flex-grow: 1;
  color: #939393;
  font-size: 18px;
  width: 50%
  line-height: 25px;
  padding: 0 150px;
  margin-bottom: 78px;
  box-sizing: border-box;
  text-align: justify;
  flex-direction: row; 
  justify-content: space-around;
`

export const Item = styled.p `
  margin: 0;
  margin-bottom: 25px;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  font-size: 20px;
`

export const ContainerImages = styled.div `
  margin-bottom: 78px;
  display: flex;
  justify-content: center
  align-items: center;
  flex-wrap: wrap;
  gap: 80px;
`

export const Client = styled.div `
  height: 84px;
  display: flex;
  align-items: center;
  margin: 0 60px;
`

export const Logo = styled.img `
  max-height: 100%;
  min-width: 100%;
  object-fit: contain;
  vertical-align: center;
`
