import styled from 'styled-components'

export const Container = styled.section `
  color: white;
  background: #1B4990;
  height: max-content;
  font-family: 'Ubuntu','Montserrat', sans-serif;
  padding: 70px 0;
  display: flex;
  justify-content: center;
  flex-direction:column;
`

export const Text = styled.h3 `
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: lighter;
  text-align: center;
  padding: 0 10px;
`