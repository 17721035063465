import styled from 'styled-components'

export const Container = styled.section `
  @media screen and (max-width: 450px) {
    padding: 50px 67px;
  };
  color: white;
  // background: rgba(0,0,0,0.6);
  height: max-content;
  font-family: 'Ubuntu','Montserrat', sans-serif;
  padding: 20px 67px 0;
  display: flex; 
  flex-direction: column;
`

export const Title = styled.h2 `
  @media screen and (max-width: 450px) {
    padding-bottom: 40px;
    font-size: 32px;
  };
  margin-top: 15px;
  margin-bottom: 0;
  padding: 10px 0 75px;
  font-size: 45px;
  line-height: 40px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
`