import styled from 'styled-components'

export const Container = styled.footer `
  @media screen and (max-width: 1000px) {
    padding: 0;
  };
  color: #939393;
  background: #272727;
  height: max-content;
  font-family: 'Ubuntu','Montserrat', sans-serif;
  padding: 10px 67px;
  display: flex;
  box-sizing: border-box; 
  flex-wrap: wrap;
`

export const Column = styled.div `
  @media screen and (max-width: 1000px) {
    width: 50%;
  };
  @media screen and (max-width: 750px) {
    width: 100%;
  };
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 25%;
  justify-content: center;
  align-items: center;
  padding: 0 14px;
  min-height: 120px;
  text-align: center;
`

export const ColumnDark = styled(Column) `
  @media screen and (max-width: 750px) {
    padding: 10px;
  };
  background: #272727; /*#171717;*/
  color: white;
`

export const Address = styled.address `
  height: 38px;
  margin-top: 10px;
`

export const Mail = styled.div `
  height: 38px;
  margin-top: 10px; 
`

export const Phone  = styled.div `
  height: 38px;
  margin-top: 10px;
`
